import * as React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import getMPTheme from "./theme/getMPTheme";
import Logo from "./components/Logo";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  backgroundImage: "none",
  padding: 4,
}));

function NavBar({ showCustomTheme, toggleCustomTheme, mode, toggleColorMode }) {
  // const handleChange = (event) => {
  //   toggleCustomTheme(event.target.value === 'custom');
  // };
  const MPTheme = createTheme(getMPTheme(mode));
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/"); // Navigate to the Home page
  };

  return (
    <ThemeProvider theme={MPTheme}>
      <StyledAppBar>
        <Container maxWidth="lg">
          <Toolbar
            variant="dense"
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <SitemarkIcon/> */}
            <IconButton
              onClick={handleNavigateHome}
              aria-label="home"
              sx={{
                p: 0,
                minWidth: 0,
                border: "none",
                background: "none",
                "&:hover": {
                  background: "none",
                },
              }}
            >
              <Logo /> {/* Logo component used inside IconButton */}
            </IconButton>
            {/* <Button
              variant="text"
              size="small"
              aria-label="Back to templates"
              startIcon={<ArrowBackRoundedIcon />}
              component="a"
              href="/material-ui/getting-started/templates/"
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              Back to templates
            </Button> */}
            {/* <IconButton
              size="small"
              aria-label="Back to templates"
              component="a"
              href="/material-ui/getting-started/templates/"
              sx={{ display: { xs: 'auto', sm: 'none' } }}
            >
              <ArrowBackRoundedIcon />
            </IconButton> */}
            {/* <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControl variant="outlined" sx={{ minWidth: 180 }}>
                <Select
                  size="small"
                  labelId="theme-select-label"
                  id="theme-select"
                  value={showCustomTheme ? 'custom' : 'material'}
                  onChange={handleChange}
                  label="Design Language"
                >
                  <MenuItem value="custom">Custom Theme</MenuItem>
                  <MenuItem value="material">Material Design 2</MenuItem>
                </Select>
              </FormControl>
              <ToggleColorMode
                data-screenshot="toggle-mode"
                mode={mode}
                toggleColorMode={toggleColorMode}
              />
            </Box> */}
          </Toolbar>
        </Container>
      </StyledAppBar>
    </ThemeProvider>
  );
}

NavBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  showCustomTheme: PropTypes.bool.isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default NavBar;
