import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function FAQ() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpen = () => setOpen(true); // Open modal
  const handleClose = () => setOpen(false); // Close modal

  const faqs = [
    {
      question: t("FAQ water pump controller?"),
      answer: t("Controller Purpose Answer"),
    },
    {
      question: t("FAQ install difficult?"),
      answer: (
        <Typography component={'span'}>
          {t("Installation Difficulty Answer")} {"  "}
          <a
            href="#!"
            onClick={handleOpen}
            style={{ color: "white", textDecoration: "underline" }}
          >
            {t("Tap here")}
          </a>{" "}
          {t("to see installation diagram")}
        </Typography>
      ),
    },
    {
      question: t("FAQ Compatibility"),
      answer: t("Compatibility Answer"),
    },
    {
      question: t("How much water can I save?"),
      answer: t("Water Saving Answer"),
    },
    {
      question: t("How much electricity can I save?"),
      answer: t("Electricity Saving Answer"),
    },
    {
      question: t("FAQ wire"),
      answer: t("Wire Answer"),
    },
  ];

  return (
    <>
      <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: "text.primary",
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          {t("FAQ")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxWidth: { sm: "100%", md: "100%" },
            textAlign: "left",
          }}
        >
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                <Typography component="h3" variant="subtitle2">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ outline: 0 }}
      >
        <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: '#76bdf5',
          }}
        >
          <CloseIcon />
        </IconButton>
          <Box sx={{ mt: 2 }}>
            <img
              src={"assets/connection_drawing.jpg"}
              alt="Example"
              style={{ width: "100%", height: "auto",}}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  outline: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "850px",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
