import { Box, Container, Stack, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function BuyNow() {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 4 },
        py: { xs: 8, sm: 4 },
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", sm: "flex" },
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: "text.primary",
            textAlign: { sm: "left", md: "center" },
          }}
        >
          {t("Buy now at")}{" "}
        </Typography>

        <Stack
          direction="row"
          spacing={6}
          useFlexGap
          sx={{ justifyContent: "center" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://www.meesho.com/"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <img
              src={"assets/Meesho_icon.png"}
              width={40}
              height={40}
              alt="meesho"
            />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.flipkart.com/"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <img
              src={"assets/flipkart_icon.svg"}
              width={40}
              height={40}
              alt="flipkart"
            />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.amazon.com/"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <img
              src={"assets/amazon_icon.svg"}
              width={40}
              height={40}
              alt="amazon"
            />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
