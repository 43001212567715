// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";

const FirebaseConfig = {
  apiKey: "AIzaSyBFJ1s-w2mgWSNCnfaFs6CqJ1Zn9Py6Ts8",
  authDomain: "fb-website-oc-01022024.firebaseapp.com",
  projectId: "fb-website-oc-01022024",
  storageBucket: "fb-website-oc-01022024.appspot.com",
  messagingSenderId: "46961187315",
  appId: "1:46961187315:web:a9c3cbe342027cb7f9da86",
  measurementId: "G-3KWF5C02QT",
};

// Initialize Firebase
const app = initializeApp(FirebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };
