import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import {Typography} from '@mui/material';
//import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

// const moveGradient = keyframes`
//   0% {
//     background-position: 0% 0%;
//   }
//   100% {
//     background-position: 100% 0%;
//   }
// `;

// const StyledBox = styled('div')(({ theme }) => ({
//   alignSelf: 'center',
//   width: '80%',
//   height: 200,
//   marginTop: theme.spacing(8),
//   borderRadius: theme.shape.borderRadius,
//   outline: '6px solid',
//   outlineColor: 'hsla(220, 25%, 80%, 0.2)',
//   border: '1px solid',
//   borderColor: theme.palette.grey[200],
//   boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
//   backgroundImage: `url(${'/assets/device1-1.png'})`,
//   backgroundSize: 'cover',
//   [theme.breakpoints.up('sm')]: {
//     marginTop: theme.spacing(10),
//     height: 700,
//   },
//   ...theme.applyStyles('dark', {
//     boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
//     //backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg'})`,
//     outlineColor: 'hsla(220, 20%, 42%, 0.1)',
//     borderColor: theme.palette.grey[700],
//   }),
// }));

export default function Hero() {

  const { t } = useTranslation();

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        mt: 6,
        background:
              "radial-gradient(ellipse at top, #0f2c45, transparent), radial-gradient(ellipse at bottom, #061e33, transparent)",
        // backgroundImage:
        //   'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          background:
              "radial-gradient(ellipse at top, #0f2c45, transparent), radial-gradient(ellipse at bottom, #061e33, transparent)",
          // backgroundImage:
          //   'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 18, sm: 20 },
          pb: { xs: 18, sm: 28 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '80%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
             fontWeight:'500'
            }}
          >
            {t("Conserve Water, Save Energy, Live")} &nbsp;

            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                fontWeight:'500',
                color: 'transparent',
                background: 'linear-gradient(to right, #00bfff -10%, #6a5acd 50%)',
                backgroundSize: '200% 100%',
                animation: 'move-gradient 5s linear',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                filter: 'brightness(1.5)',
                pb: { xs: 2, sm: 2 },
                //animation: `${moveGradient} 5s linear infinite`,
              })}
            >
              {t("Sustainably")}
            </Typography>
          </Typography>
          {/* <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Explore our cutting-edge dashboard, delivering high-quality solutions
            tailored to your needs. Elevate your experience with top-tier features
            and services.
          </Typography> */}
          {/* <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: '350px' } }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              fullWidth
              slotProps={{
                htmlInput: {
                  autoComplete: 'off',
                  'aria-label': 'Enter your email address',
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: 'fit-content' }}
            >
              Start now
            </Button>
          </Stack> */}
          {/* <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        {/* <StyledBox id="image" /> */}
      </Container>
    </Box>
  );
}
