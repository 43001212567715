import * as React from 'react';
import Divider from '@mui/material/Divider';
import Hero from './components/Hero';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Languages from './components/Languages';
import BuyNow from './components/BuyNow';
import ProductDetails from './components/ProductDetails';


export default function MainPage() {
  //const [mode, setMode] = React.useState('light');
  // const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const MPTheme = createTheme(getMPTheme(mode));
  // const defaultTheme = createTheme({ palette: { mode } });

  // This code only runs on the client side, to determine the system color preference
  // React.useEffect(() => {
  //   // Check if there is a preferred mode in localStorage
  //   const savedMode = localStorage.getItem('themeMode');
  //   if (savedMode) {
  //     setMode(savedMode);
  //   } else {
  //     // If no preference is found, it uses system preference
  //     const systemPrefersDark = window.matchMedia(
  //       '(prefers-color-scheme: dark)',
  //     ).matches;
  //     setMode(systemPrefersDark ? 'dark' : 'light');
  //   }
  // }, []);

  // const toggleColorMode = () => {
  //   const newMode = mode === 'dark' ? 'light' : 'dark';
  //   setMode(newMode);
  //   localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
  // };

  // const toggleCustomTheme = () => {
  //   setShowCustomTheme((prev) => !prev);
  // };

  return (
    <div>
    {/* <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline /> */}
      {/* you can delete this NavBar component since it's just no navigate to other pages */}
      {/* <NavBar
        toggleCustomTheme={toggleCustomTheme}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
      /> */}
      {/* <AppAppBar /> */}
      <Hero />
      <div>
        {/* <LogoCollection /> */}
        {/* <Features /> */}
        {/* <Divider /> */}
        {/* <Testimonials /> */}
        {/* <Divider /> */}
        {/* <Highlights /> */}
        <Divider />
        <ProductDetails />
        <Divider />
        <BuyNow/>
        <FAQ />
        <Divider />
        <Languages/>
        <Divider />
        <Footer />
      </div>
    {/* </ThemeProvider> */}
    </div>
  );
}
