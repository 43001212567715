import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © "}
      <Link color="text.secondary" href="https://oldcrux.com/">
        OldCrux Private Limited.
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      > */}
      {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        > */}
      {/* <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <SitemarkIcon />
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
              Join the newsletter
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Subscribe for weekly updates. No spams ever!
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                  },
                }}
                sx={{ width: '250px' }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ flexShrink: 0 }}
              >
                Subscribe
              </Button>
            </Stack>
          </Box> */}
      {/* </Box> */}
      {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Product
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Features
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Testimonials
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Highlights
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Pricing
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            FAQs
          </Link>
        </Box> */}
      {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Company
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            About us
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Careers
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Press
          </Link>
        </Box> */}
      {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Legal
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Privacy
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Contact
          </Link>
        </Box> */}
      {/* </Box> */}

      <Box
        sx={{
          display: { xs: "flex", sm: "flex" },
          flexDirection: "column",
          gap: 4,
          minWidth: { xs: "100%", sm: "100%" },
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex" },
            flexDirection: "column",
            textAlign: { xs: "center", sm: "right" },
            alignItems: { xs: "center", sm: "flex-end" },
            //width:{ xs: '100%', sm: '30%' },
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              flexDirection: "row",
              textAlign: { xs: "center", sm: "right" },
              alignItems: { xs: "center", sm: "flex-end" },
              //width:{ xs: '100%', sm: '30%' },
              gap: 1,
            }}
          >
            <WhatsAppIcon sx={{ fontSize: "large", color: "green" }} />
            <Typography
              variant="body2"
              sx={{ fontWeight: "medium", color: "text.secondary" }}
            >
              +91 11111 11111
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              flexDirection: "row",
              textAlign: { xs: "center", sm: "right" },
              alignItems: { xs: "center", sm: "flex-end" },
              //width:{ xs: '100%', sm: '30%' },
              gap: 1,
            }}
          >
            <EmailOutlinedIcon
              sx={{ fontSize: "large", color: "text.secondary" }}
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: "medium", color: "text.secondary" }}
            >
              <Link
                color="text.secondary"
                variant="body2"
                href="mailto:support@oldcrux.com"
              >
                support@oldcrux.com
              </Link>
            </Typography>
          </Box>

          <Typography
            variant="body2"
            sx={{ fontWeight: "medium", color: "text.secondary" }}
          >
            Plot 2305 / 11962 Saptasati Nagar, Mancheswar, <br />
            Bhubaneswar, Odisha 751017
          </Typography>
          <Link
            component={RouterLink}
            color="text.secondary"
            variant="body2"
            to="/feedback"
          >
            Contact Us
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          pt: { xs: 4, sm: 4 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          gap: 2,
        }}
      >
        <div>
          {/* <Link color="text.secondary" variant="body2" href="#">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms of Service
          </Link> */}
          <Copyright />
        </div>

        <Stack
          direction="row"
          spacing={2}
          useFlexGap
          sx={{ justifyContent: "center", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://facebook.com/mui"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://x.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
