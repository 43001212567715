import * as React from "react";
import {  useEffect } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

export default function FeedbackSuccessPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a language is saved in sessionStorage and apply it
    const savedLanguage = sessionStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleNavigateHome = () => {
    navigate("/"); // Navigate to the Home page
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 4 },
        py: { xs: 8, sm: 10 },
        textAlign: "left",
        position: "relative",
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}
      >
        {/* <SendOutlinedIcon sx={{color:"red"}}  /> */}
        <Typography
          component="h2"
          variant="subtitle1"
          sx={{
            color: "white",
            textAlign: "center",
            fontSize: 'small'
          }}
        >
          {t("Feedback Submitted")}{" "}
        </Typography>
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}
      >
        <Button
          onClick={handleNavigateHome}
          type="submit"
          variant="outlined"
          color="primary"
          size="small"
          sx={{ flexShrink: 0 }}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}
