import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledBox = styled("div")(({ theme, backgroundImage }) => ({
  alignSelf: "center",
  width: "80%",
  height: 200, // for small screen
  marginTop: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundColor:"#001629",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 500,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    //backgroundImage: `url(${'/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg'})`,
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function ProductDetails() {
  const { t } = useTranslation();
  return (
    <Container
      id="ProductDetails"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 12 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          gutterBottom
          sx={{ color: "#4c9eef", fontWeight: "500" }}
        >
          {t("Water Pump Controller")}
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: "text.primary", fontWeight: "300" }}
        >
          {t("Introducing")}
        </Typography>
      </Box>

      <StyledBox id="image" backgroundImage="/assets/device1.png" />

      <Box
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 12 },
          width: { sm: "100%", md: "80%" },
          textAlign: { sm: "left", md: "center" },
          //display: "flex",
          flexDirection: { xs: "column", md: "column" },
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "text.primary",
            fontWeight: "300",
            background: "linear-gradient(to right, #0086ff 0%, #56adfc 100%)",
            borderRadius: "25px",
            display: "inline-block",
            padding: 2,
          }}
        >
          {t("Now your water pump knows to stop running when the overhead tank is full and runs again when the water level in your tank goes low")}
        </Typography>

        <Box
          sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 12 },
            width: { sm: "100%", md: "100%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledBox id="image" backgroundImage="/assets/device2.png" />
          <StyledBox id="image" backgroundImage="/assets/device3.png" />
        </Box>

        <Typography
          variant="h4"
          sx={{
            color: "text.primary",
            fontWeight: "300",
            background: "linear-gradient(to right, #0086ff 0%, #56adfc 100%)",
            borderRadius: "25px",
            display: "inline-block",
            padding: 2,
          }}
        >
          {t("Save water, save electricity, save money. Enjoy peace of mind with our new water pump controller. It is more than just a device; it is a lifestyle upgrade")}
        </Typography>
      </Box>

      {/* <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === 'Enterprise' ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                },
                tier.title === 'Professional' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles('dark', {
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    },
                    tier.title === 'Professional'
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )}
                </Box>
                <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                    },
                    tier.title === 'Professional'
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === 'Professional'
                          ? { color: 'primary.light' }
                          : { color: 'primary.main' },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={'span'}
                      sx={[
                        tier.title === 'Professional'
                          ? { color: 'grey.50' }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color={tier.buttonColor}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid> */}
    </Container>
  );
}
